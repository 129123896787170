import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import { graphql } from "gatsby";

import { Button, HeaderCTA, SectionCTA, SectionJobs } from "components";
import { Layout } from "containers";

const PageHome = ({
  data: {
    config: { header },
    imageCreative,
    imageEmployer,
    imageSubscribe,
    jobs,
    site: {
      siteMetadata: { subscribe },
    },
  },
}) => {
  const hasJobs = jobs?.edges?.length > 0;

  const headerProps = {
    cards: [
      {
        button: {
          label: "Find Work",
          to: "/jobs",
        },
        content: (
          <>
            <Typography>
              Here you'll discover companies that love remote work.
            </Typography>
            <Typography>
              Discover new jobs, and learn about the company vision and remote
              process.
            </Typography>
          </>
        ),
        heading: "👊 Creatives,",
        image: imageCreative,
      },
      {
        button: {
          color: "secondary",
          label: "Post a Job",
          to: "/jobs/new",
        },
        content: (
          <>
            <Typography>Hire the best remote creatives.</Typography>
            <Typography>
              Designers, engineers, writers and more that are all remote by
              default.
            </Typography>
          </>
        ),
        heading: "🤝 Employers,",
        image: {
          className: "align-right",
          ...imageEmployer,
        },
      },
    ],
    ...header,
  };

  const sectionJobsProps = {
    bgcolor: !hasJobs ? "grey.200" : null,
    heading: hasJobs
      ? "Jobs"
      : "We're working with the coolest companies to find you the coolest remote jobs. Subscribe below to be the first to hear about new gigs.",
    headingProps: !hasJobs && {
      className: "cta",
      component: "h4",
    },
    jobs,
    link: {
      label: "See all jobs",
      path: "/jobs",
    },
    showSubscribeCard: false,
  };

  const sectionCTAProps = {
    content: (
      <>
        <Button
          className="button-cta"
          color="primary"
          component={OutboundLink}
          href={subscribe}
          size="large"
          target="_blank"
        >
          Subscribe
        </Button>
        <Typography>No spam, ever. Your email is safe with us.</Typography>
      </>
    ),
    heading: "While you do the robot, we'll find you your next remote gig",
    image: imageSubscribe,
  };

  return (
    <Layout>
      <HeaderCTA {...headerProps} />
      <SectionJobs {...sectionJobsProps} />
      <SectionCTA {...sectionCTAProps} />
    </Layout>
  );
};

PageHome.propTypes = {
  data: PropTypes.shape({
    config: PropTypes.shape({
      header: PropTypes.shape({
        heading: PropTypes.string,
      }),
      id: PropTypes.string,
    }),
    imageCreative: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
    }),
    imageEmployer: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
    }),
    imageSubscribe: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          base64: PropTypes.string,
          aspectRatio: PropTypes.number,
          src: PropTypes.string,
          srcSet: PropTypes.string,
          sizes: PropTypes.string,
        }),
      }),
      name: PropTypes.string,
    }),
    jobs: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              companyLogo: PropTypes.oneOfType([
                PropTypes.shape({
                  localFiles: PropTypes.arrayOf(
                    PropTypes.shape({
                      childImageSharp: PropTypes.shape({
                        fluid: PropTypes.shape({
                          base64: PropTypes.string,
                          aspectRatio: PropTypes.number,
                          src: PropTypes.string,
                          srcSet: PropTypes.string,
                          sizes: PropTypes.string,
                        }),
                      }),
                      name: PropTypes.string,
                    })
                  ),
                }),
                PropTypes.string,
              ]),
              companyName: PropTypes.string,
              date: PropTypes.string,
              id: PropTypes.string,
              jobCategory: PropTypes.oneOf([
                "Art",
                "Audio",
                "Design",
                "Development",
                "Social",
                "Video",
                "Writing",
              ]),
              jobTitle: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        subscribe: PropTypes.string,
      }),
    }),
  }),
};

export const homePageQuery = graphql`
  query HomePageQuery {
    config: pagesYaml(id: { eq: "PageHome" }) {
      header {
        heading
      }
      id
    }
    imageCreative: file(name: { eq: "mascot-creative" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90, traceSVG: { color: "#ced0d4" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      name
    }
    imageEmployer: file(name: { eq: "mascot-employer" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 90, traceSVG: { color: "#ced0d4" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      name
    }
    imageSubscribe: file(name: { eq: "mascot-subscribe" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90, traceSVG: { color: "#474d60" }) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      name
    }
    jobs: allAirtable(
      filter: { data: { isPublished: { eq: 1 } }, table: { eq: "Jobs" } }
      limit: 6
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          data {
            # NOTE: use this query if companyLogo is an attachment type
            companyLogo {
              localFiles {
                childImageSharp {
                  fluid(maxWidth: 480, quality: 90) {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
            companyLogoUrl
            companyName
            date(formatString: "MMMM DD, YYYY")
            id
            jobCategory
            jobTitle
          }
          id
        }
      }
    }
    site {
      siteMetadata {
        subscribe
      }
    }
  }
`;

export default PageHome;
